/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  Image,
  HStack,
  Button,
  Flex,
  Card,
  CardBody,
  CardFooter,
  VStack,
} from '@mybridge/ui';
import Link from 'next/link';
import { EventDefaultImage } from '@mybridge/icons/EventDefaultImage';
import {
  EventClockIcon,
  EventHostIcon,
  LocationIcon,
  LinkIcon,
  CalendarIcon,
  AlarmIcon,
  AuthorIcon,
  AuthorIconEvent
} from '@mybridge/icons';
import { PostBoxContext, usePostBoxContext } from '../context';
import moment from 'moment';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';

export const EventPostBody = ({fromPublicEvent=false}) => {
  const { post, userProfileInfo, acceptEventLoading, acceptEvent } =
    useContext(PostBoxContext);

  const {
    id,
    title,
    author,
    media,
    start_time,
    end_time,
    attendees,
    event_type,
  } = post;

  const attending = attendees?.find?.(
    (a) => a?.user?.id === userProfileInfo?.id
  );

  return (
    <>
      <Card bg="#E4E6EB" mb={3} border="1px solid #E4E6EB" borderRadius="20px" ml={!fromPublicEvent?"60px":"0px"} width="70%">
        <CardBody p={0}>
          {media[0]?.file && (
            <Image
              src={media[0].file}
              alt={title}
              maxW="100%" // Ensure the image takes full width of the Card
              borderTopRadius="20px" // Match borderRadius of Card
              objectFit="cover"
            />
          )}
          {/* Content Section */}
          <VStack align="flex-start" spacing={2} flex={1} p={4}>
            {' '}
            {/* Add padding for content */}
            <Text
              as={Link}
              href={`/myevents/details/${id}`}
              fontWeight="medium"
              color="#3D5A80"
              lineHeight="1.2"
              fontSize="20px"
              isTruncated
              whiteSpace="general"
            >
              {title?.length > 85
                ? `${title.slice(0, 85)}...`
                : title || 'Event'}
            </Text>
            <HStack>
              <CalendarIcon color="#9F9F9F" width="19px" height="17px" />
              <Text fontSize="14px">
                {moment(start_time).local().format('MMM DD, YYYY')}
              </Text>
            </HStack>
            <HStack>
              <AlarmIcon color="#9F9F9F" width="19px" height="17px" />
              <Text fontSize="14px">
                {moment(start_time).local().format('hh:mm A')} -{' '}
                {moment(end_time).local().format('hh:mm A')}
              </Text>
            </HStack>
            {post?.author?.location && (
              <HStack>
                <LocationIcon color={"#9F9F9F"} width="19px" height="17px" />
                <Text fontSize="14px">{post?.author?.location}</Text>
              </HStack>
            )}
            {(attending?.status === 'ACCEPT' ||
              userProfileInfo?.id === author?.id) && (
              <HStack>
                <LinkIcon color="#9F9F9F" width="19px" height="17px" />
                <Text fontSize="14px" isTruncated>
                  <Link
                    href={`/myevents/details/${id}`}
                  >{`${process.env.BASE_PUBLIC_URL}myevents/details/${id}`}</Link>
                </Text>
              </HStack>
            )}
            <HStack>
              <AuthorIconEvent width="19px" height="17px" />
              <Text fontSize="14px">
                <Link href={`/p/${author?.slug}`}>
                  {getUserFullName(author)}
                </Link>
              </Text>
            </HStack>
          </VStack>
        </CardBody>

        <CardFooter p={4}>
          <Flex justify="space-between" align="center">
            {/* RSVP/Register Button */}
            {attending?.status === 'ACCEPT' ? (
              <Button
                as={Link}
                href={`/myevents/details/${id}`}
                size="sm"
                height="26px"
                fontSize="12px"
                color="#5B5B5B"
              >
                View Details
              </Button>
            ) : (
              <Button
                isLoading={acceptEventLoading}
                onClick={() => acceptEvent(post)}
                size="sm"
                border="1px solid #00000033"
                borderRadius="27px"
                fontSize="12px"
                color="#5B5B5B"
                bg="none"
              >
                {event_type === 'general' ? 'RSVP' : 'Register'}
              </Button>
            )}
          </Flex>
        </CardFooter>
      </Card>
    </>
  );
};
