/* eslint-disable @nx/enforce-module-boundaries */
import Image from 'next/image';
import { Image as ImageChakra, Text } from '@mybridge/ui';
import Link from 'next/link';
// import Image from 'next/image';
import PreloadImage from 'react-preload-image';
import { Box } from '@mybridge/ui/layout';
import { VideoPlayer } from '@mybridge/ui/video-player';
import { AdVideoPlayer } from '@mybridge/ui/video-player/ad-videoPlayer';
import { VideoWithThumbnailPlayer } from '@mybridge/ui/video-player-thumbnail';
import GifPlayer from '@mybridge/ui/gif-player';
import { useContext, useEffect, useRef, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { PostBoxContext } from '../context';
import styles from './media-player.module.scss';
import { HStack, Stack } from '@mybridge/ui/layout';
export const MediaDisplay = ({ onLoaded, isOnlyPreview,fromArticle=false }) => {
  const { post, handleAdClicksRouting } = useContext(PostBoxContext);
  const {
    media,
    cover_image,
    type,
    title,
    id,
    ad_format_detail,
    video_thumbnail,
  } = post??{};
  const isArticle = type === 3;
  const isAdPost = type === 4;
  const [images_, setImages_] = useState([]);
  const [videos_, setVideos_] = useState([]);
  const [gifs_, setGifs_] = useState([]);
  const [adVideoSrc, setAdVideoSrc] = useState(null);
  const videoRef = useRef(null);

  const isProfileBannerPost =
    post?.content === 'USER_PROFILE_BANNER_PIC_UPDATE' ||
    post?.content === 'COMPANY_PROFILE_BANNER_PIC_UPDATE';

  useEffect(() => {
    prepareMedia();
  }, [media]);

  // useEffect(() => {
  //   prepareGifs();
  // }, [gif]);

  const prepareMedia = async () => {
    const vids = [];
    const imgs = [];
    const gifs = [];
    for (let m of media ?? []) {
      const { file, type } = m ?? {};
      if (type === 'VIDEO') {
        vids.push(file);
      } else if (type === 'IMAGE') {
        imgs.push(file);
      } else if (type === 'GIF') {
        gifs.push(file);
      }
    }
    setVideos_(vids);
    setImages_(imgs);
    setGifs_(gifs);
  };
  // const prepareGifs = async () => {
  //   const gs = [];
  //   if (gif?.length) {
  //     gs.push(gif);
  //   }
  //   setGifs_(gs);
  // };

  const handleLoading = (val) => {
    onLoaded?.();
  };

  const handleVideoAdDuration = (duration) => {
    if (duration?.playedSeconds > 5) {
      handleAdClicksRouting('video', id);
    }
  };

  useEffect(() => {
    if (ad_format_detail?.video) {
      setAdVideoSrc(ad_format_detail?.video);
    }
  }, [ad_format_detail?.video]);

  // console.log('adVideoSrc', adVideoSrc, videoRef);

  return (
    <>
      {videos_?.length ? (
        <Box pos="relative" overflow="hidden" className={styles.mediaPlayer}>
          <VideoWithThumbnailPlayer
            onLoad={() => handleLoading(false)}
            src={videos_?.[0]}
            height="100%"
            getVideoDuration={(e) => console.log()}
            videoThumbnail={video_thumbnail}
            width="100%"
            style={{
              backgroundColor: 'black',
              // maxHeight: '70vh',
              width: '100%',
              height: '100%',
            }}
            className="player"
          />
        </Box>
      ) : (
        <>
          {isArticle ? (
            <>
              {cover_image && (
                // <Image
                //   className={styles.preloadImage}
                //   src={cover_image}
                //   lazy
                //   htmlHeight="auto"
                //   htmlWidth="100%"
                //   alt={'Post Image'}
                // />
                <Link
                  // maxH="400px"
                  overflow="hidden"
                  // w="100%"
                  href={`/post/${id}`}
                  onClick={() => {
                    localStorage?.removeItem('commentCLicked', true);
                  }}
                >
                  <div style={{border:fromArticle?"1px solid #E4E6EB":"",height:fromArticle?"225px":""}} className={styles.articalImgContainer}>
                    <img
                  
                      src={cover_image}
                      alt={id}
                      className={styles.articalImage}
                    />
                  </div>
                  {/* <Image
                    className={styles.preloadImage}
                    src={cover_image}
                    alt={title}
                    width="640"
                    height="300"
                    loading="lazy"
                    style={{ borderRadius: '8px 8px 0 8px' }}
                  /> */}
                </Link>
              )}
            </>
          ) : (
            <>
              {images_?.length ? (
                images_.length > 1 ? (
                  <Link
                    // maxH="400px"
                    overflow="hidden"
                    borderRadius="20px"
                    // w="100%"
                    href={`/post/${id}`}
                    onClick={() => {
                      localStorage?.removeItem('commentCLicked', true);
                    }}
                  >
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{
                        350: 1,
                        750: 1,
                        900: images_?.length > 3 ? 2 : 2,
                      }}
                    >
                      <Masonry columnsCount={1} gutter="2px">
                        {images_?.slice(0, 4).map((img, index) => (
                          <>
                            <ImageChakra
                              key={Math.random()}
                              _hover={{
                                opacity: '0.5',
                                boxShadow: '0px 0px 12px 0px #00000033',
                              }}
                              style={{
                                height: `${
                                  images_?.length == 2
                                    ? '300px'
                                    : index + 1 == images_?.length - 1 &&
                                      images_?.length == 3
                                    ? '400px'
                                    : '200px'
                                }`,
                                filter: `${
                                  index + 1 == images_?.length - 1 &&
                                  images_?.length > 4
                                    ? 'brightness(0.5)'
                                    : ''
                                }`,
                              //  marginBottom:"10px",
                                objectFit: 'cover',
                                ...(images_?.length === 2
                                  ? {
                                      borderTopLeftRadius:
                                        index === 0 ? '20px' : '0px',
                                      borderBottomRightRadius:
                                        index === 1 ? '20px' : '0px',
                                      borderTopRightRadius:
                                        index === 0 ? '0px' : '20px',
                                      borderBottomLeftRadius:
                                        index === 1 ? '0px' : '20px',
                                    }
                                  : images_?.length === 4 ||
                                    images_?.length === 5
                                  ? {
                                      borderTopLeftRadius:
                                        index === 0 ? '20px' : '0px',
                                      borderTopRightRadius:
                                        index === 1 ? '20px' : '0px',
                                      borderBottomLeftRadius:
                                        index === 2 ? '20px' : '0px',
                                      borderBottomRightRadius:
                                        index === 3 ? '20px' : '0px',
                                    }
                                  : images_?.length === 3
                                  ? {
                                      borderTopLeftRadius:
                                        index === 0 ? '20px' : '0px 0px',
                                      borderTopRightRadius:
                                        index === 0 ? '20px' : '0px 0px',
                                      borderTopRightRadius:
                                        index === 1 ? '20px' : '0px 0px',
                                      borderBottomLeftRadius:
                                        index === 2 ? '20px' : '0px 0px',
                                      borderBottomRightRadius:
                                        index === 1 ? '20px' : '0px',
                                    }
                                  : {}),
                              }}
                              src={img}
                              alt={id}
                              width="300"
                              height="300"
                              loading="lazy"
                            />
                            {index === 3 && images_?.length > 4 && (
                              <Stack
                                style={{
                                  position: 'absolute',
                                  top: '74%',
                                  left: '76%',
                                  transform: 'translate(-50%, -50%)',
                                  textAlign: 'center',
                                }}
                              >
                                <Text size="2rem" color="#ffffff">
                                  + {images_?.length - 4}
                                </Text>
                              </Stack>
                            )}
                          </>
                        ))}
                      </Masonry>
                    </ResponsiveMasonry>
                  </Link>
                ) : (
                  <>
                    {/* <PreloadImage
                      className={styles.preloadImage}
                      src={images_?.[0]}
                      lazy
                    /> */}
                    <Link
                      // maxH="400px"
                      overflow="hidden"
                      // w="100%"
                      href={`/post/${id}`}
                      onClick={() => {
                        localStorage?.removeItem('commentCLicked', true);
                      }}
                    >
                      <div className={styles.postImgContainer}>
                        <img
                          src={images_?.[0]}
                          alt={id}
                          className={
                            isProfileBannerPost
                              ? styles.postbannerImage
                              : styles.postImage
                          }
                          style={{ minHeight: '100%' }}
                        />
                      </div>

                      {/* <Image
                        className={styles.preloadImage}
                        src={images_?.[0]}
                        alt={id}
                        width="640"
                        height="300"
                        loading="lazy"
                      /> */}
                    </Link>
                  </>
                )
              ) : (
                <>
                  {gifs_?.length ? (
                    <Box
                      pos="relative"
                      borderRadius="20px"
                      width="85%"
                      height="516px"
                      overflow="hidden"
                    >
                      {/* <Image
                        className={styles.preloadImage}
                        src={gifs_?.[0]}
                        lazy
                        htmlHeight="400px"
                        htmlWidth="100%"
                        alt={'Post Image'}
                      /> */}
                      {/* <ImageChakra
                        className={styles.preloadImage}
                        src={gifs_?.[0]}
                        alt={id}
                        width="640"
                        height="300"
                        loading="lazy"
                      /> */}
                      <GifPlayer
                        onLoad={() => handleLoading(false)}
                        videoUrl={gifs_?.[0]}
                        height="100%"
                        width="100%"
                        videoThumbnail={video_thumbnail}
                        style={{
                          backgroundColor: 'black',
                          maxHeight: '70vh',
                          width: '100%',
                          height: '100%',
                        }}
                        className="player"
                      />
                      <></>
                    </Box>
                  ) : (
                    ''
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {isAdPost && ad_format_detail?.image ? (
        <Image
          className={styles.preloadImage}
          src={ad_format_detail?.image}
          alt={id}
          width="640"
          height="300"
          loading="lazy"
          // objectFit='cover'
          onClick={() =>
            !isOnlyPreview
              ? handleAdClicksRouting('conversion', id, media?.destination_url)
              : undefined
          }
          cursor={!isOnlyPreview ? 'pointer' : 'default'}
        />
      ) : (
        ''
      )}
      {isAdPost && adVideoSrc ? (
        <Box pos="relative" overflow="hidden" className={styles.mediaPlayer}>
          <AdVideoPlayer
            onLoad={() => handleLoading(false)}
            src={adVideoSrc}
            height="100%"
            width="100%"
            ref={videoRef}
            getVideoDuration={(e) => handleVideoAdDuration(e)}
            style={{
              backgroundColor: 'black',
              // maxHeight: '70vh',
              width: '100%',
              height: '100%',
            }}
            className="player"
          />
        </Box>
      ) : (
        ''
      )}
    </>
  );
};
